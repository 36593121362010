import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";

import { updateServiceListPC } from "../../../../../store/service/proposalAndContractService";

import LoaderSpin from "../../../Commons/LoaderSpin";

import ClearIcon from "@mui/icons-material/Clear";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import TextStyles from "../../../../../Constants/TextStyles";
import Colors from "../../../../../Constants/Colors";
import Styled from "./ServicesStyles";
import SelectServiceType from "../../../Commons/SelectServiceType";

const EditServices = ({ selectedData, show, handleClose }) => {
  const dispatch = useDispatch();
  const [removedData, setRemovedData] = useState([]);
  const service_list = selectedData && selectedData.service_list;
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);

  const {
    register,
    reset,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      servicesList: service_list,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "servicesList",
  });

  const formsubmit = (data) => {
    const Obj = {
      _id: selectedData && selectedData._id,
      service_list: data.servicesList,
    };
    dispatch(updateServiceListPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        handleClose();
        message.success(`${data.payload.message}`);
        reset();
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="ToMakeModalOnRight500px"
      fullscreen
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        className="modal-content"
        style={{ height: "100%" }}
      >
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <TextStyles.FontSize20px>Edit services</TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          {fields.map((item, index) => {
            const serviceRate = watch(`servicesList.${index}.service_rate`);
            const serviceRateCount = watch(
              `servicesList.${index}.service_rate_count`
            );
            const serviceTotal = serviceRate * (serviceRateCount || 1);

            return (
              <Styled.EditServiceContainer key={item.id}>
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="service_name" className="mb-2">
                    Service name
                  </label>
                  <div className="d-flex gap-2 justify-content-between align-items-center">
                    <TextStyles.InputRectangle
                      id="service_name"
                      type="text"
                      placeholder="Service name"
                      name="service_name"
                      {...register(`servicesList.${index}.service_name`, {
                        required: "Service name is required.",
                      })}
                    />
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minWidth: 40, maxWidth: 40 }}
                    >
                      {fields.length !== 1 && (
                        <DeleteForeverRoundedIcon
                          className="edit_services_imgBtn"
                          onClick={() => {
                            remove(index);
                            setRemovedData([...removedData, item._id]);
                          }}
                          sx={{
                            fontSize: 24,
                            color: Colors.font2,
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </div>
                  </div>

                  {errors &&
                    errors.servicesList &&
                    errors.servicesList[index] &&
                    errors.servicesList[index].service_name && (
                      <TextStyles.InValidFeedback>
                        {errors.servicesList[index].service_name.message}
                      </TextStyles.InValidFeedback>
                    )}
                </TextStyles.FontSize14px>
                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="service_rate" className="mb-2">
                    Rate
                  </label>
                  <div className="d-flex flex-wrap  gap-3 align-items-center mb-1">
                    <Styled.inputBox>
                      <span>
                        {selectedData &&
                          selectedData.currency &&
                          selectedData.currency.symbol}
                      </span>
                      <input
                        style={{ width: 80 }}
                        type="number"
                        min={"0"}
                        step={"0.001"}
                        id="service_rate"
                        name="service_rate"
                        placeholder="00.00"
                        {...register(`servicesList.${index}.service_rate`, {
                          required: "Rate is required.",
                        })}
                      />
                    </Styled.inputBox>
                    <Controller
                      defaultValue={"Flat fee"}
                      control={control}
                      name={`servicesList.${index}.service_rate_type`}
                      render={({ field: { onChange, value } }) => (
                        <SelectServiceType
                          placeholder="Select"
                          onChange={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </div>

                  {errors &&
                    errors.servicesList &&
                    errors.servicesList[index] &&
                    errors.servicesList[index].service_rate && (
                      <TextStyles.InValidFeedback>
                        {errors.servicesList[index].service_rate.message}
                      </TextStyles.InValidFeedback>
                    )}
                </TextStyles.FontSize14px>

                <TextStyles.FontSize14px className="mb-3">
                  <label htmlFor="desc" className="mb-2">
                    Description
                  </label>
                  <TextStyles.Textarea
                    rows={4}
                    id="desc"
                    name="desc"
                    placeholder="Add a note..."
                    {...register(`servicesList.${index}.desc`)}
                  />
                </TextStyles.FontSize14px>

                <div
                  className="mb-3"
                  style={{
                    width: "100%",
                    height: "1px",
                    border: "0.5px solid #edeef2",
                  }}
                />

                <TextStyles.FontSize18px className="d-flex justify-content-between align-items-center three-dots w-100 mb-3">
                  <div className="three-dots" style={{ minWidth: 100 }}>
                    Service charge
                  </div>
                  <div
                    className="three-dots"
                    style={{ color: Colors.primary, minWidth: 50 }}
                  >
                    {selectedData &&
                      selectedData.currency &&
                      selectedData.currency.symbol}

                    {serviceTotal > 0
                      ? parseFloat(serviceTotal).toFixed(2)
                      : "00.00"}
                  </div>
                </TextStyles.FontSize18px>

                <div
                  className="mb-3"
                  style={{
                    width: "100%",
                    height: "1px",
                    border: "0.5px solid #edeef2",
                  }}
                />
              </Styled.EditServiceContainer>
            );
          })}
          <TextStyles.FontSize14px
            className="d-flex justify-content-end"
            onClick={() =>
              append({
                service_name: "",
                service_rate: "",
                service_rate_type: "Flat fee",
                desc: "",
              })
            }
            style={{ cursor: "pointer" }}
          >
            <AddCircleOutlineTwoToneIcon
              sx={{ color: Colors.font1, fontSize: 22 }}
            />
            <span className="ms-2">Add another service</span>
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-around align-items-center">
            <TextStyles.CancelButton onClick={handleClose}>
              Cancel
            </TextStyles.CancelButton>
            <TextStyles.Button disabled={isLoading ? true : false}>
              {isLoading ? <LoaderSpin /> : "Save"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditServices;
