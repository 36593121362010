import React, { useState } from "react";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { message } from "antd";
import TextStyles from "../../../../../../Constants/TextStyles";
import Styled from "../../PageOne/PageOne";
import { useDispatch, useSelector } from "react-redux";
import { updateDepositPC } from "../../../../../../store/service/proposalAndContractService";
import Colors from "../../../../../../Constants/Colors";
import DepositModal from "../../PageModals/DepositModal.jsx";

const Deposit = () => {
  const selectedData = useSelector(
    (state) => state.proposalAndContract.selectedData
  );
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const heading = "Deposit";

  const isDepositRequired =
    selectedData && selectedData.deposit && selectedData.deposit.required
      ? true
      : false;

  const toggleDepositViewHandler = () => {
    const Obj = {
      _id: selectedData._id,
      deposit: {
        required: isDepositRequired ? false : true,
      },
    };
    dispatch(updateDepositPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        message.success(
          isDepositRequired
            ? "Clause hidden successfully!"
            : "Clause unhidden successfully!"
        );
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div>
      <Styled.ReviewContentBox className="mt-4">
        <div className="edit-delete-icons">
          <span onClick={toggleDepositViewHandler}>
            {isDepositRequired ? (
              <VisibilityOffOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            ) : (
              <VisibilityOutlinedIcon
                sx={{
                  cursor: "pointer",
                  color: Colors.font2,
                  opacity: 0.8,
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            )}
          </span>

          {isDepositRequired && (
            <BorderColorOutlinedIcon
              sx={{
                cursor: "pointer",
                color: Colors.font2,
                opacity: 0.8,
                marginLeft: 2,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowModal(true)}
            />
          )}
        </div>

        <TextStyles.FontSize20px className="mb-3">
          <span className={`fw-medium ${isDepositRequired ? "" : "disabled"}`}>
            {heading}
          </span>
        </TextStyles.FontSize20px>

        <TextStyles.FontSize14px
          className={`lh-lg ${isDepositRequired ? "" : "disabled"}`}
        >
          Upon the acceptance of this Agreement, a deposit of
          <span
            className="mx-2"
            style={{ color: Colors.primary, fontWeight: 500 }}
          >
            {selectedData.currency.symbol}
            {parseFloat(
              selectedData.billing_scheduled.deposit.value || 0
            ).toFixed(2)}
          </span>
          is required before work will commence.
        </TextStyles.FontSize14px>
      </Styled.ReviewContentBox>

      {showModal && (
        <DepositModal
          show={showModal}
          handleClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Deposit;
