import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ClearIcon from "@mui/icons-material/Clear";
import { BackButton } from "../../Commons/BackAndForwardButton";

import Styled from "./CreateInvoiceStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { useLocation } from "react-router-dom";
import ProtectedRoute from "../../../auth/ProtectedRoute";
import InvoiceSetup from "./InvoiceSetup/InvoiceSetup";
import { invoiceActions } from "../../../../store/storage/invoiceSlice";
import SetupProject from "./SetupProject/SetupProject";
import SetupClient from "./ClientSetup/SetupClient";
import QuickSetup from "./QuickInvoice/QuickSetup";
import CreateClient from "./CreateClient/CreateClient";
import ReviewInvoice from "../ReviewInvoice/ReviewInvoice";
import SendInvoice from "../SendInvoice/SendInvoice";
import DiscardChangesModal from "../InvoiceModals/DiscardChangesModal";
import SaveChangesModal from "../InvoiceModals/SaveChangesModal";
import SelectInvoice from "./SelectInvoice/SelectInvoice";
import { saveAsDraftInvoice } from "../../../../store/service/invoiceService";
import { message } from "antd";

const CreateInvoice = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );

  const createInvoiceMode = useSelector(
    (state) => state.invoices.createInvoiceMode
  );

  // modals
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);

  const discardChangesHandler = () => {
    history.push("/db/invoices");
    dispatch(invoiceActions.selectedInvoiceResetAction());
  };

  const saveChangesHandler = () => {
    const Obj = {
      ...selectedInvoiceData,
      status: "Draft",
    };
    dispatch(saveAsDraftInvoice(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        history.push("/db/invoices");
        dispatch(invoiceActions.selectedInvoiceResetAction());
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <div
      style={{
        background: Colors.backgroundGrey,
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          background: Colors.white,
          zIndex: 200,
        }}
        className="fixed-top"
      >
        <div className="px-2 py-3">
          <TextStyles.FontSize14px
            className="d-none d-sm-flex"
            style={{
              position: "absolute",
              left: "0.75rem",
              top: "1.3rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ClearIcon
              onClick={() => {
                if (selectedInvoiceData || createInvoiceMode) {
                  setShowDiscardModal(true);
                  setConfirmDialog({
                    onConfirm: () => discardChangesHandler(),
                  });
                } else {
                  history.goBack();
                }
              }}
              sx={{
                fontSize: 27,
                color: Colors.font2,
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
            />
          </TextStyles.FontSize14px>
          <TextStyles.FontSize20px
            style={{
              textAlign: "center",
              fontWeight: 500,
              fontSize: 27,
            }}
          >
            Create invoice
          </TextStyles.FontSize20px>
          <TextStyles.FontSize14px
            className="d-none d-sm-flex"
            style={{
              position: "absolute",
              right: "0.75rem",
              top: "1.5rem",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            {selectedInvoiceData &&
              ((selectedInvoiceData.invoice_services &&
                selectedInvoiceData.invoice_services.length > 0) ||
                (selectedInvoiceData.line_item_services &&
                  selectedInvoiceData.line_item_services.length > 0)) && (
                <Styled.SaveAndCloseButtun
                  onClick={() => {
                    setShowSaveChangesModal(true);
                    setConfirmDialog({
                      onConfirm: () => saveChangesHandler(),
                    });
                  }}
                >
                  Save & Close
                </Styled.SaveAndCloseButtun>
              )}
          </TextStyles.FontSize14px>
        </div>

        <div className="d-flex">
          <Styled.ProgressBar>
            <div
              className="col"
              style={{
                height: "100%",
                backgroundColor: Colors.primary,
                color: Colors.white,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              Setup
            </div>
            <div
              className="col"
              style={
                location &&
                (location.pathname === "/db/invoices/create/review" ||
                  location.pathname === "/db/invoices/create/send")
                  ? {
                      height: "100%",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      justifyContent: "center",
                      display: "flex",
                    }
                  : { justifyContent: "center", display: "flex" }
              }
            >
              Review
            </div>
            <div
              className="col"
              style={
                location && location.pathname === "/db/invoices/create/send"
                  ? {
                      height: "100%",
                      backgroundColor: Colors.primary,
                      color: Colors.white,
                      justifyContent: "center",
                      display: "flex",
                    }
                  : { justifyContent: "center", display: "flex" }
              }
            >
              Send
            </div>
          </Styled.ProgressBar>
        </div>
      </div>

      <div style={{ paddingTop: 100 }}>
        {location && location.pathname !== "/db/invoices/create" && (
          <div
            style={{ position: "fixed", left: 20, top: "50vh", zIndex: 200 }}
            onClick={() => history.goBack()}
          >
            <BackButton />
          </div>
        )}

        {location && location.pathname === "/db/invoices/create" && (
          <ProtectedRoute
            path="/db/invoices/create"
            exact
            component={SelectInvoice}
          />
        )}
        {location && location.pathname === "/db/invoices/create/setup" && (
          <ProtectedRoute
            path="/db/invoices/create/setup"
            exact
            component={InvoiceSetup}
          />
        )}

        {location &&
          location.pathname === "/db/invoices/create/setupClient" && (
            <ProtectedRoute
              path="/db/invoices/create/setupClient"
              exact
              component={SetupClient}
            />
          )}
        {location &&
          location.pathname === "/db/invoices/create/setupProject" && (
            <ProtectedRoute
              path="/db/invoices/create/setupProject"
              exact
              component={SetupProject}
            />
          )}
        {location &&
          location.pathname === "/db/invoices/create/quickInvoice" && (
            <ProtectedRoute
              path="/db/invoices/create/quickInvoice"
              exact
              component={QuickSetup}
            />
          )}
        {location &&
          location.pathname === "/db/invoices/create/createClient" && (
            <ProtectedRoute
              path="/db/invoices/create/createClient"
              exact
              component={CreateClient}
            />
          )}
        {location && location.pathname === "/db/invoices/create/review" && (
          <ProtectedRoute
            path="/db/invoices/create/review"
            exact
            component={ReviewInvoice}
          />
        )}
        {location && location.pathname === "/db/invoices/create/send" && (
          <ProtectedRoute
            path="/db/invoices/create/send"
            exact
            component={SendInvoice}
          />
        )}
        {showDiscardModal && (
          <DiscardChangesModal
            show={showDiscardModal}
            confirmDialog={confirmDialog}
            handleClose={() => setShowDiscardModal(false)}
          />
        )}
        {showSaveChangesModal && (
          <SaveChangesModal
            show={showSaveChangesModal}
            confirmDialog={confirmDialog}
            handleClose={() => setShowSaveChangesModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default CreateInvoice;
