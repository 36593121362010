import styled from "styled-components";
import Colors from "./Colors";
import sizes from "./sizes";

const FontSize27px = styled.div`
  font-size: 1.69rem;
  color: ${Colors.font1};
`;

const FontSize22px = styled.div`
  font-size: 1.375rem;
  color: ${Colors.font1};
`;

const FontSize20px = styled.div`
  font-size: 1.25rem;
  color: ${Colors.font1};
`;

const FontSize18px = styled.div`
  font-size: 1.125rem;
  color: ${Colors.font1};
`;

const FontSize16px = styled.div`
  font-size: 1rem;
  color: ${Colors.font1};
`;

const FontSize15px = styled.div`
  font-size: 0.9375rem;
  color: ${Colors.font1};
`;

const FontSize14px = styled.div`
  font-size: 0.875rem;
  color: ${Colors.font1};
`;

const GreyFont20px = styled.div`
  font-size: 1.25rem;
  color: ${Colors.font2};
`;

const GreyFont18px = styled.div`
  font-size: 1.125rem;
  color: ${Colors.font2};
`;

const GreyFont16px = styled.div`
  font-size: 1rem;
  color: ${Colors.font2};
`;

const GreyFont15px = styled.div`
  font-size: 0.9375rem;
  color: ${Colors.font2};
`;

const GreyFont14px = styled.div`
  font-size: 0.875rem;
  color: ${Colors.font2};
`;

const InputRectangle = styled.input`
  width: 100%;
  height: ${sizes.formInputHeight};
  margin: 0 0 6px;
  padding: 0 1rem;
  border-radius: ${sizes.formInputBorderRadius};
  border: 1px solid ${Colors.borderInput};
  background-color: ${Colors.white};
  transition: border 0.4s ease;
  :focus {
    border: 1px solid ${Colors.primary};
  }

  &:hover {
    border: 1px solid ${Colors.primary};
  }
`;

const Button = styled.button`
  width: ${(props) => props.width || "100%"};
  height: 3.15rem;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  color: ${(props) => props.color || `${Colors.white}`};
  border-radius: ${sizes.formInputBorderRadius};
  border: 1px solid ${(props) => props.borderColor || "inherit"};
  background-color: ${(props) => props.backgroundColor || `${Colors.primary}`};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${(props) =>
      props.backgroundColor
        ? `${Colors.hoverDeleteBtn}`
        : `${Colors.hoverButtonColor}`};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : `${Colors.primary}`};
  }
  &:disabled {
    opacity: 0.75 !important;
  }
`;

const CancelButton = styled.div`
  width: 100%;
  height: ${sizes.formInputHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins, serif;
  font-size: 0.875rem;
  cursor: pointer;
  justify-content: center;
  display: flex;
  color: ${(props) => props.color || `${Colors.font2}`};
  border-radius: ${sizes.formInputBorderRadius};
  background-color: ${(props) => props.backgroundColor || `${Colors.white}`};
  border: 1px solid ${(props) => props.border || `${Colors.font2}`};
  transition: all 0.4s ease;

  &:hover {
    color: ${Colors.primary};
    border: 1px solid ${Colors.primary};
  }

  &:active {
    color: ${Colors.font2};
    border: 1px solid ${Colors.font2};
  }
`;

const InValidFeedback = styled.div`
  color: ${Colors.red};
  display: flex;
  font-size: 0.875rem;
  margin-left: 7px;
`;

const CheckBox = styled.div`
  padding-left: 0px !important;
  .form-check-input {
    width: ${(props) => (props.size ? props.size : "1.125rem")};
    height: ${(props) => (props.size ? props.size : "1.125rem")};
    margin: 0 !important;
    box-shadow: none !important;
    border-color: ${Colors.font2};
    cursor: pointer;
  }

  .form-check-input:checked {
    width: ${(props) => (props.size ? props.size : "1.125rem")};
    height: ${(props) => (props.size ? props.size : "1.125rem")};
    box-shadow: none !important;
    background-color: ${(props) =>
      props.checkedColor ? props.checkedColor : `${Colors.primary}`};
    border-color: ${(props) =>
      props.checkedColor ? props.checkedColor : `${Colors.primary}`};
  }

  .form-check-label {
    padding-left: 0.875rem;
  }
`;

const RadioButton = styled.div`
  padding-left: 0px !important;
  .form-check-input {
    width: 1.25rem;
    height: 1.25rem;
    margin: 1px 0px 0px 0px;
    box-shadow: none !important;
    border: 2px solid ${Colors.font2};
    cursor: pointer;
  }

  .form-check-input:checked {
    width: 1.25rem;
    height: 1.25rem;
    box-shadow: none !important;
    background-color: ${Colors.primary};
    border-color: ${Colors.primary};
  }

  .form-check-label {
    padding-left: 0.875rem;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  border-radius: ${sizes.formInputBorderRadius};
  padding: 1rem;
  border: 1px solid ${Colors.borderInput};
  background-color: ${Colors.white};
  transition: border 0.4s ease;

  &:hover {
    border: 1px solid ${Colors.primary};
  }

  &:focus {
    border: 1px solid ${Colors.primary};
  }
`;

const HoverEffectSpan = styled.span`
  color: ${Colors.font1};
  padding: ${(props) =>
    props.active
      ? "0"
      : props.sort
      ? "0.4rem 0.4rem 0.4rem 1rem"
      : "0.4rem 1rem"};
  border-radius: 0.4rem;
  cursor: pointer;
  font-weight: 500;
  opacity: 0.9;
  background-color: ${Colors.white};
  transition: background-color 0.4s ease;
  &:hover {
    background-color: ${Colors.backgroundPink};
  }

  &:active {
    opacity: 0.8;
  }
`;

export default {
  FontSize27px,
  FontSize22px,
  FontSize20px,
  FontSize18px,
  FontSize16px,
  FontSize15px,
  FontSize14px,
  GreyFont20px,
  GreyFont18px,
  GreyFont16px,
  GreyFont15px,
  GreyFont14px,
  InputRectangle,
  InValidFeedback,
  Button,
  CancelButton,
  CheckBox,
  RadioButton,
  Textarea,
  HoverEffectSpan,
};
