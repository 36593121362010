import React, { useState, useEffect, useMemo } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { message } from "antd";
import moment from "moment/moment";
import Joyride, { EVENTS, STATUS } from "react-joyride";

import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import EventIcon from "@mui/icons-material/Event";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import NoTaskImage from "../../../../Assets/assetsnew/no-task.svg";

import {
  createQuickTask,
  fetchTasks,
  editTaskDueDate,
  deleteTask,
  updateTaskStatus,
  updateOrderingOfTasks,
} from "../../../../store/service/TaskService";

import EditTask from "./Components/EditTask";
import DeleteModal from "../../Commons/DeleteModal";

import Styled from "./TasksStyles";
import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";

import SelectClients from "../../Commons/SelectClients";
import SelectProjects from "../../Commons/SelectProjects";
import SelectTaskStatus from "../../Commons/SelectTaskStatus";
import Pagination from "../../Commons/Pagination";
import SelectDate from "../../Commons/SelectDate";
import LoaderSpin from "../../Commons/LoaderSpin";
import { timeTrackingActions } from "../../../../store/storage/timetrackingSlice";
import { taskActions } from "../../../../store/storage/TaskSlice";
import {
  createActivityList,
  fetchAllActivityLists,
  start_timer_activity,
} from "../../../../store/service/timeTrackingService";
import { updateUserTourSetupData } from "../../../../store/service/AuthService";
import TaskList from "./Components/TaskList";
import QuickTask from "./Components/QuickTask";

import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { ReactTooltip } from "../../Commons/ReactTooltip";

const stepsTask = [
  {
    title: (
      <TextStyles.FontSize18px>Create your first task</TextStyles.FontSize18px>
    ),
    content: (
      <TextStyles.GreyFont14px>
        After creating a task, you can see all your created tasks in the task
        list.
      </TextStyles.GreyFont14px>
    ),
    target: ".task_tour",
    disableBeacon: true,
  },
];

export default function Tasks() {
  const [showInput, setShowInput] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.tasks.isLoading);
  const isTTLoading = useSelector((state) => state.timeTracking.isLoading);
  const taskData = useSelector((state) => state.tasks.taskData);
  const taskTotalCount = useSelector((state) => state.tasks.taskTotalCount);
  const refreshHeaderTask = useSelector(
    (state) => state.tasks.refreshHeaderTask
  );
  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );

  const projectOptionsData = useSelector(
    (state) => state.project.projectOptionsData
  );

  const toggleFilter = useSelector((state) => state.tasks.toggleFilter);

  const filteredData = useSelector((state) => state.tasks.filteredData);

  const filterdDateRange =
    filteredData && filteredData.filterdDateRange
      ? filteredData.filterdDateRange
      : null;
  const filteredProject =
    filteredData && filteredData.filteredProject
      ? filteredData.filteredProject
      : null;

  const filteredClient =
    filteredData && filteredData.filteredClient
      ? filteredData.filteredClient
      : null;

  const filteredTaskStatus =
    filteredData && filteredData.filteredTaskStatus
      ? filteredData.filteredTaskStatus
      : "todo";

  const sortingData = useSelector((state) => state.tasks.sortingData);

  const selectedSortingOne =
    sortingData && sortingData.selectedSortingOne
      ? sortingData.selectedSortingOne
      : "orders";
  const selectedSortingTwo =
    sortingData && sortingData.selectedSortingTwo
      ? sortingData.selectedSortingTwo
      : "desc";

  const [selectedTaskData, setSelectedTaskData] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const [updateRefresh, setUpdateRefresh] = useState(false);

  const toggleShowFilter = () => {
    dispatch(taskActions.toggleAction());
  };

  const { register, reset, handleSubmit } = useForm();

  const defaultData = useMemo(() => {
    return (
      taskData &&
      taskData.map((item) => {
        const repeat_task_id = item.repeat_task_id;
        const _id = item._id;
        const due_date = item.due_date ? moment(item.due_date).toDate() : null;
        const hide_until_date = item.hide_until_date
          ? moment(item.hide_until_date).toDate()
          : null;
        const client_id = item.client_id ? item.client_id : null;
        const project_id = item.project_id ? item.project_id : null;
        const status = item.status;
        const task_name = item.task_name ? item.task_name : null;
        const repeat_task = item.repeat_task ? item.repeat_task : null;
        const createdAt = item.createdAt
          ? moment(item.createdAt).toDate()
          : null;

        return {
          _id,
          id: _id,
          due_date,
          hide_until_date,
          client_id,
          project_id,
          status,
          task_name,
          repeat_task,
          repeat_task_id,
          createdAt,
        };
      })
    );
  }, [taskData]);

  const { control: control2, reset: reset2 } = useForm({
    defaultValues: {
      update_task: defaultData && defaultData,
    },
  });

  const { fields: fields2 } = useFieldArray({
    control: control2,
    name: "update_task",
  });

  useEffect(() => {
    reset2({
      update_task: defaultData && defaultData,
    });
  }, [taskData]);

  const RemoveInputHandler = (e) => {
    if (e.keyCode === 8 && e.target.value === "") {
      setShowInput(false);
    }
  };

  // sorting invoice
  const [openSorting, setOpenSorting] = useState(false);

  const hideSortingHandler = () => {
    setOpenSorting(false);
  };
  const handleOpenSortingChange = (newOpen) => {
    setOpenSorting(newOpen);
  };

  const sortingOptionChangeHandler = (event) => {
    dispatch(
      taskActions.sortingAction({
        selectedSortingOne: event.target.value,
      })
    );
  };

  const sortingOrderingChangeHandler = (event) => {
    dispatch(
      taskActions.sortingAction({
        selectedSortingTwo: event.target.value,
      })
    );
  };

  //pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    setPage(1);
  }, [filteredData, itemsPerPage, refreshHeaderTask]);

  useEffect(() => {
    if (taskData && taskData.length === 0) {
      if (page > 1) {
        setPage(page - 1);
      }
    }
  }, [taskData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    let start_time = null;
    let end_time = null;

    if (filterdDateRange && typeof filterdDateRange === "object") {
      start_time = moment(filterdDateRange[1]).endOf("days").toDate();
      end_time = moment(filterdDateRange[0]).startOf("days").toDate();
    } else {
      if (filterdDateRange === "Last 7 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time).subtract(6, "d").startOf("days").toDate();
      } else if (filterdDateRange === "Last 15 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(14, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 30 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(29, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 45 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(44, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 60 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(59, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "Last 90 days") {
        start_time = moment().endOf("days").toDate();
        end_time = moment(start_time)
          .subtract(89, "d")
          .startOf("days")
          .toDate();
      } else if (filterdDateRange === "This month") {
        start_time = moment().endOf("month").toDate();
        end_time = moment().startOf("month").toDate();
      } else if (filterdDateRange === "Last month") {
        start_time = moment().subtract(1, "months").endOf("month").toDate();
        end_time = moment().subtract(1, "months").startOf("month").toDate();
      } else if (filterdDateRange === "This year") {
        start_time = moment().endOf("year").toDate();
        end_time = moment().startOf("year").toDate();
      } else if (filterdDateRange === "Last year") {
        start_time = moment().subtract(1, "years").endOf("year").toDate();
        end_time = moment().subtract(1, "years").startOf("year").toDate();
      }
    }

    const Obj = {
      page: page,
      perPage: itemsPerPage,
      start_time,
      end_time,
      client_id: filteredClient !== 0 ? filteredClient : null,
      project_id: filteredProject !== 0 ? filteredProject : null,
      status: filteredTaskStatus ? filteredTaskStatus : "todo",
      sortBy: selectedSortingOne,
      orderBy: selectedSortingTwo,
    };
    dispatch(fetchTasks(Obj));
  }, [
    filteredData,
    updateRefresh,
    page,
    itemsPerPage,
    sortingData,
    refreshHeaderTask,
  ]);

  const formsubmit = (data) => {
    const Obj = {
      task_name: data.task_name,
    };
    dispatch(createQuickTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        // setShowInput(false);
        reset();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}`);
      }
    });
  };

  const doneTaskCallApi = (data) => {
    let Obj = {
      task_id: data._id,
      status: "done",
    };
    dispatch(updateTaskStatus(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success("Task completed successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const setDueDateApiCall = (newDate, data) => {
    const Obj = {
      _id: data._id,
      due_date: moment(newDate).toDate(),
    };
    dispatch(editTaskDueDate(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const todoTaskCallApi = (data) => {
    let Obj = {
      task_id: data._id,
      status: "todo",
    };
    dispatch(updateTaskStatus(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        message.success("Task undo successfully!");
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const deleteTaskHandler = (data) => {
    const Obj = {
      task_id: data && data._id,
    };
    dispatch(deleteTask(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        setUpdateRefresh(!updateRefresh);
        handleCloseDeleteModal();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  const startTimerHandler = (data) => {
    const Obj = {
      project_id: data.project_id ? data.project_id._id : null,
      client_id: data.client_id ? data.client_id._id : null,
      activity_name: data.task_name,
      start_time: moment().toDate(),
    };

    dispatch(
      timeTrackingActions.addNewActivityList({ activity_name: data.task_name })
    );
    dispatch(timeTrackingActions.startTimer(Obj));
    dispatch(createActivityList({ activity_name: data.task_name })).then(
      (data) => {
        if (data.meta.requestStatus === "fulfilled") {
          dispatch(fetchAllActivityLists());
        }
      }
    );
    dispatch(start_timer_activity(Obj));
  };

  const onDragEndhandler = (event) => {
    const { active, over } = event;
    const items = fields2;

    if (over && active.id !== over.id) {
      const sourceIndex = items.findIndex((item) => item.id === active.id);
      const targetIndex = items.findIndex((item) => item.id === over.id);
      const data = arrayMove(items, sourceIndex, targetIndex);

      dispatch(taskActions.dragAndDropAction(data));
      const updateTasks = data.map((data) => data._id);
      dispatch(updateOrderingOfTasks({ tasks: updateTasks })).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          setUpdateRefresh(!updateRefresh);
          // message.success(`${data.payload.message}`);
        } else {
          message.error(`${data.error.message}!`);
        }
      });
    }
  };

  // mobile device

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const sortingContent = () => {
    return (
      <TextStyles.FontSize14px>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_task_name">
            <AssignmentIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Task name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_task_name"
              type="radio"
              name="sorting"
              value="task_name"
              checked={selectedSortingOne === "task_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_company_name">
            <FolderSharedTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Client name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_company_name"
              type="radio"
              name="sorting"
              value="company_name"
              checked={selectedSortingOne === "company_name"}
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_project_name">
            <FolderTwoToneIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Project name</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_project_name"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "project_name"}
              value="project_name"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_due_date">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Due date</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_due_date"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "due_date"}
              value="due_date"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="sorting_createdAt">
            <EventIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">CreatedAt</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="sorting_createdAt"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "createdAt"}
              value="createdAt"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="orders">
            <DragIndicatorIcon sx={{ color: Colors.font2, fontSize: 21 }} />
            <span className="ms-2">Custom</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="orders"
              type="radio"
              name="sorting"
              checked={selectedSortingOne === "orders"}
              value="orders"
              onChange={sortingOptionChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <hr
          style={{
            borderColor: "f9f9f9",
            marginTop: 10,
            marginBottom: 10,
            borderTop: "none",
            opacity: 0.1,
          }}
        />

        <Styled.Sorting>
          <label className="form-check-label" htmlFor="asc">
            <ArrowUpwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Ascending (A-Z)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="asc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "asc"}
              value="asc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>
        <Styled.Sorting>
          <label className="form-check-label" htmlFor="desc">
            <ArrowDownwardTwoToneIcon
              sx={{ color: Colors.font2, fontSize: 21 }}
            />
            <span className="ms-2">Descending (Z-A)</span>
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              id="desc"
              type="radio"
              name="ordering"
              checked={selectedSortingTwo === "desc"}
              value="desc"
              onChange={sortingOrderingChangeHandler}
            />
          </div>
        </Styled.Sorting>

        <Styled.Sorting className="mt-3">
          <TextStyles.GreyFont16px
            onClick={hideSortingHandler}
            style={{
              width: "100%",
              fontWeight: 500,
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            Done
          </TextStyles.GreyFont16px>
        </Styled.Sorting>
      </TextStyles.FontSize14px>
    );
  };

  // Tour setup
  const tourData = useSelector((state) => state.auth.userTourSetupData);
  const [run, setRun] = useState(true);

  useEffect(() => {
    setRun(tourData && tourData.task_tour);
  }, [tourData]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }
    if ([EVENTS.TOUR_END, EVENTS.SKIP].includes(type)) {
      dispatch(updateUserTourSetupData({ task_tour: false }));
    }
  };

  const setSortingActiveHandler = (selectedSort) => {
    dispatch(
      taskActions.sortingAction({
        selectedSortingOne: selectedSort,
        selectedSortingTwo: selectedSortingTwo === "desc" ? "asc" : "desc",
      })
    );
  };

  return (
    <div className="pt-3">
      <div className="d-flex flex-wrap gap-3 justify-content-sm-between mb-4">
        <div>
          <TextStyles.FontSize20px className="font-weight-500">
            Tasks
          </TextStyles.FontSize20px>
        </div>
        <div
          className="d-flex flex-wrap gap-3 align-items-center justify-content-center"
          style={{ marginLeft: "auto" }}
        >
          <div className="d-flex align-items-center">
            <TextStyles.HoverEffectSpan onClick={toggleShowFilter}>
              Filter
            </TextStyles.HoverEffectSpan>

            <TextStyles.GreyFont20px className="mx-2">
              |
            </TextStyles.GreyFont20px>

            <TextStyles.FontSize16px className="me-2">
              <Popover
                placement="bottomRight"
                content={sortingContent}
                trigger="click"
                open={openSorting}
                onOpenChange={handleOpenSortingChange}
              >
                <TextStyles.HoverEffectSpan sort={true}>
                  Sort
                  <ArrowDropDownOutlinedIcon
                    sx={{
                      color: Colors.font2,
                      fontSize: 27,
                      transform: openSorting ? "rotate(180deg)" : "",
                    }}
                  />
                </TextStyles.HoverEffectSpan>
              </Popover>
            </TextStyles.FontSize16px>
          </div>
          <label htmlFor="create_new_task" className="mb-0">
            <Styled.NewCreate
              onClick={() => {
                setShowInput(true);
              }}
              className="task_tour"
            >
              <div className="d-flex justify-content-center align-items-center">
                <AddOutlinedIcon sx={{ fontSize: 20, color: Colors.white }} />
                <span className="ms-1 pe-2">New task</span>
              </div>
            </Styled.NewCreate>
          </label>
        </div>
      </div>

      {toggleFilter && (
        <div className="d-flex flex-wrap justify-content-start mb-3">
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Date range
            </TextStyles.FontSize14px>
            <SelectDate
              defaultValue={filterdDateRange && filterdDateRange}
              onChange={(value) => {
                dispatch(
                  taskActions.filterAction({
                    filterdDateRange: value,
                  })
                );
              }}
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Project
            </TextStyles.FontSize14px>
            <SelectProjects
              defaultValue={filteredProject && filteredProject}
              options={projectOptionsData}
              onChange={(e) =>
                dispatch(
                  taskActions.filterAction({
                    filteredProject: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Client
            </TextStyles.FontSize14px>
            <SelectClients
              options={clientOptionData}
              defaultValue={filteredClient && filteredClient}
              onChange={(e) =>
                dispatch(
                  taskActions.filterAction({
                    filteredClient: e.target.value,
                  })
                )
              }
            />
          </div>
          <div className="me-3 mb-2">
            <TextStyles.FontSize14px className="d-flex mb-2">
              Task status
            </TextStyles.FontSize14px>
            <SelectTaskStatus
              defaultValue={filteredTaskStatus && filteredTaskStatus}
              onChange={(e) =>
                dispatch(
                  taskActions.filterAction({
                    filteredTaskStatus: e.target.value,
                  })
                )
              }
            />
          </div>
        </div>
      )}

      <Styled.TodoRectangle className="mt-4">
        <TextStyles.FontSize14px
          className="d-flex gap-2 align-items-center w-100"
          style={{ fontWeight: 500 }}
        >
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ minWidth: 25, maxWidth: 25 }}
          />
          <div
            className="d-flex justify-content-start w-100"
            style={{ minWidth: 130 }}
          >
            <ReactTooltip anchorId="task_name" content="Sort by task name" />
            <span
              className="three-dots hover-task-name"
              id="task_name"
              role="button"
              onClick={() => setSortingActiveHandler("task_name")}
            >
              Task name
              {selectedSortingOne === "task_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-task-name"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-task-name"
                    />
                  )}
                </>
              )}
            </span>
          </div>
          <div
            className="d-none d-md-flex justify-content-start w-100"
            style={{ maxWidth: 140, minWidth: 30 }}
          >
            <ReactTooltip anchorId="projects" content="Sort by project name" />
            <span
              className="three-dots hover-projects"
              id="projects"
              role="button"
              onClick={() => setSortingActiveHandler("project_name")}
            >
              Project name
              {selectedSortingOne === "project_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-projects"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-projects"
                    />
                  )}
                </>
              )}
            </span>
          </div>

          <div
            className="d-none d-md-flex justify-content-start w-100"
            style={{ maxWidth: 130, minWidth: 30 }}
          >
            <ReactTooltip
              anchorId="client_name"
              content="Sort by client name"
            />
            <span
              className="three-dots hover-clients"
              id="client_name"
              role="button"
              onClick={() => setSortingActiveHandler("company_name")}
            >
              Client name
              {selectedSortingOne === "company_name" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-clients"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-clients"
                    />
                  )}
                </>
              )}
            </span>
          </div>

          <div
            className="d-none d-md-flex justify-content-center w-100"
            style={{ maxWidth: 105, minWidth: 30 }}
          >
            <ReactTooltip anchorId="createdAt" content="Sort by created date" />
            <span
              className="three-dots hover-createdAt"
              id="createdAt"
              role="button"
              onClick={() => setSortingActiveHandler("createdAt")}
            >
              CreatedAt
              {selectedSortingOne === "createdAt" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-createdAt"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-createdAt"
                    />
                  )}
                </>
              )}
            </span>
          </div>
          <div
            className="d-flex justify-content-center w-100"
            style={{ maxWidth: 105, minWidth: 30 }}
          >
            <ReactTooltip anchorId="due-date" content="Sort by due date" />
            <span
              className="three-dots hover-due-date"
              id="due-date"
              role="button"
              onClick={() => setSortingActiveHandler("due_date")}
            >
              Due date
              {selectedSortingOne === "due-date" ? (
                <>
                  {selectedSortingTwo === "desc" ? (
                    <ArrowDownwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  ) : (
                    <ArrowUpwardRoundedIcon sx={{ fontSize: "1.2rem" }} />
                  )}
                </>
              ) : (
                <>
                  {selectedSortingTwo === "asc" ? (
                    <ArrowUpwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-due-date"
                    />
                  ) : (
                    <ArrowDownwardRoundedIcon
                      sx={{
                        fontSize: "1.2rem",
                      }}
                      className="visible-due-date"
                    />
                  )}
                </>
              )}
            </span>
          </div>
          <div
            className="d-none d-md-flex justify-content-end position-relative w-100"
            style={{ maxWidth: 207, minWidth: 207 }}
          />

          <div
            className="d-flex d-md-none justify-content-end position-relative w-100"
            style={{
              minWidth: 70,
              maxWidth: 70,
            }}
          />
        </TextStyles.FontSize14px>
      </Styled.TodoRectangle>

      {showInput && (
        <QuickTask
          showInput={showInput}
          taskData={taskData}
          handleSubmit={handleSubmit}
          formsubmit={formsubmit}
          register={register}
          RemoveInputHandler={RemoveInputHandler}
          isLoading={isLoading}
        />
      )}

      <div className="position-relative w-100 h-100">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          modifiers={[restrictToVerticalAxis]}
          onDragStart={({ active }) => {
            console.log("starting drag", active);
          }}
          onDragEnd={onDragEndhandler}
          // onDragCancel={() => {
          //   setActive(null);
          // }}
        >
          <SortableContext
            items={fields2 ? fields2 : []}
            strategy={verticalListSortingStrategy}
          >
            {fields2 && fields2.length > 0 ? (
              fields2.map((data, index) => {
                return (
                  <TaskList
                    key={index}
                    index={index}
                    data={data}
                    taskData={taskData}
                    filteredTaskStatus={filteredTaskStatus}
                    doneTaskCallApi={doneTaskCallApi}
                    todoTaskCallApi={todoTaskCallApi}
                    deleteTaskHandler={deleteTaskHandler}
                    startTimerHandler={startTimerHandler}
                    setDueDateApiCall={setDueDateApiCall}
                    setSelectedTaskData={setSelectedTaskData}
                    setShowDeleteModal={setShowDeleteModal}
                    setShow={setShow}
                    showInput={showInput}
                    isTTLoading={isTTLoading}
                    isLoading={isLoading}
                    control2={control2}
                    Controller={Controller}
                    setConfirmDialog={setConfirmDialog}
                  />
                );
              })
            ) : isLoading && taskData === null ? (
              <Styled.ListBox>
                <LoaderSpin color={Colors.primary} />
              </Styled.ListBox>
            ) : (
              !isLoading &&
              !showInput && (
                <Styled.ListBox>
                  <img src={NoTaskImage} alt="no-task-img" />
                  <TextStyles.GreyFont18px className="mt-2">
                    There are no tasks created yet.
                  </TextStyles.GreyFont18px>
                </Styled.ListBox>
              )
            )}
          </SortableContext>
        </DndContext>
      </div>

      <div className="d-flex justify-content-center py-4">
        {taskTotalCount > itemsPerPage && (
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={Math.ceil(taskTotalCount / itemsPerPage)}
            page={page}
          />
        )}
      </div>
      <Joyride
        steps={stepsTask}
        run={run}
        disableBeacon
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        scrollToFirstStep={false}
        scrollOffset={200}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            primaryColor: Colors.primary,
            textColor: Colors.font1,
            zIndex: 50,
            overlayColor: "rgba(0, 0, 0, 0.2)",
            spotlightShadow: "0 0 15px rgba(0, 0, 0, 0)",
          },
          beacon: {
            zIndex: 50,
            left: 50,
          },
          overlay: {
            zIndex: 50,
          },
          tooltipTitle: {
            fontSize: 18,
            margin: 0,
          },
          tooltipContent: {
            padding: "10px 0 0 0",
          },
        }}
        defaultStyles={{
          tooltipContent: {
            padding: "0px",
          },
        }}
      />
      {show && (
        <EditTask
          setUpdateRefresh={setUpdateRefresh}
          updateRefresh={updateRefresh}
          selectedTaskData={selectedTaskData}
          handleClose={handleClose}
          show={show}
        />
      )}
      <DeleteModal
        title="task"
        confirmDialog={confirmDialog}
        showDeleteModal={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />
    </div>
  );
}
