import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updateClientDetailPC } from "../../../../store/service/proposalAndContractService";

import ClearIcon from "@mui/icons-material/Clear";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";

import { message } from "antd";
import LoaderSpin from "../../Commons/LoaderSpin";

import TextStyles from "../../../../Constants/TextStyles";

import Styled from "./ReviewStyles";

import { Modal } from "react-bootstrap";
import Colors from "../../../../Constants/Colors";
import SelectCountry from "../../Commons/SelectCountry";
import Constant from "../../Commons/Constant";
import { pcActions } from "../../../../store/storage/proposalAndContractSlice";

const PCEditClientInfo = ({ selectedData, show, handleClose }) => {
  const isLoading = useSelector((state) => state.proposalAndContract.isLoading);
  const selectedClientContacts = useSelector(
    (state) => state.proposalAndContract.selectedClientContacts
  );
  const [addContact, setAddContact] = useState(false);
  const primaryContactData =
    selectedData &&
    selectedData.client_id &&
    selectedData.client_id.contacts &&
    selectedData.client_id.contacts.filter(
      (d) => d.email === (selectedData && selectedData.client_email)
    );
  let primaryContactList;
  if (primaryContactData.length === 1) {
    primaryContactList = primaryContactData && primaryContactData[0];
  } else if (primaryContactData.length > 1) {
    primaryContactList =
      selectedData &&
      selectedData.client_id &&
      selectedData.client_id.contacts &&
      selectedData.client_id.contacts[0];
  } else {
    primaryContactList = null;
  }
  const [selectedContact, setSelectedContact] = useState(primaryContactList);
  const [primaryContact, setPrimaryContact] = useState(false);

  const dispatch = useDispatch();
  const {
    register,
    reset,
    control,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      street:
        selectedData && selectedData.client_id && selectedData.client_id.street,
      suite:
        selectedData && selectedData.client_id && selectedData.client_id.suite,
      city:
        selectedData && selectedData.client_id && selectedData.client_id.city,
      zip_code:
        selectedData &&
        selectedData.client_id &&
        selectedData.client_id.zip_code,
      country:
        selectedData &&
        selectedData.client_id &&
        selectedData.client_id.country &&
        Constant.countryOptions.filter(
          (data) => data.name === selectedData.client_id.country
        )[0],
      state:
        selectedData && selectedData.client_id && selectedData.client_id.state,
      tax_id_label:
        selectedData &&
        selectedData.client_id &&
        selectedData.client_id.tax_id_label,
      tax_id_number:
        selectedData &&
        selectedData.client_id &&
        selectedData.client_id.tax_id_number,
    },
  });

  const validateEmail = (value) => {
    const trimmedValue = value.trim();
    const emailPattern = /^[A-Za-z0-9+._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2}/i;
    return (
      emailPattern.test(trimmedValue) || "Please enter a valid email address."
    );
  };

  const formsubmit = (data) => {
    const Obj = {
      pc_id: selectedData && selectedData._id,
      client_id:
        selectedData && selectedData.client_id && selectedData.client_id._id,
      client_email:
        selectedContact && primaryContact
          ? data.contact_email
          : selectedContact
          ? selectedContact.email
          : null,
      zip_code: data.zip_code,
      street: data.street,
      suite: data.suite,
      city: data.city,
      country: data.country ? data.country.name : null,
      state: data.state,
      tax_id_label: data.tax_id_label,
      tax_id_number: data.tax_id_number,
      newContact: addContact
        ? {
            contact_name: data.contact_name,
            contact_email: data.contact_email,
          }
        : null,
    };

    const oldClientContacts = [...selectedClientContacts];
    const newClientContacts = [
      ...oldClientContacts,
      {
        contact_name: data.contact_name,
        email: data.contact_email,
      },
    ];
    dispatch(updateClientDetailPC(Obj)).then((data) => {
      if (data.meta.requestStatus === "fulfilled") {
        if (addContact) {
          dispatch(pcActions.selectedClientContactAction(newClientContacts));
        }
        handleClose();
        reset();
        message.success(`${data.payload.message}`);
      } else {
        message.error(`${data.error.message}!`);
      }
    });
  };

  return (
    <Modal
      scrollable={true}
      show={show}
      onHide={handleClose}
      contentClassName="ToMakeModalBorderRudius12px"
    >
      <form
        onSubmit={handleSubmit(formsubmit)}
        style={{ height: "100%" }}
        className="modal-content"
      >
        <Modal.Header className="d-flex justify-content-between">
          <TextStyles.FontSize20px>
            {selectedData &&
              selectedData.client_id &&
              selectedData.client_id.company_name}
          </TextStyles.FontSize20px>
          <ClearIcon
            sx={{
              color: Colors.font2,
              cursor: "pointer",
              opacity: 0.7,
              "&:hover": {
                opacity: 1,
              },
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body>
          <TextStyles.FontSize14px className="row">
            <div className="col-12 col-sm-6 mb-2">
              <label htmlFor="street_address" className="mb-2">
                Street address
              </label>
              <TextStyles.InputRectangle
                id="street_address"
                type="text"
                placeholder="Street address"
                name="street"
                {...register("street")}
              />
            </div>
            <div className="col-12 col-sm-6 mb-2">
              <label htmlFor="suite_address" className="mb-2">
                Suite address
              </label>
              <TextStyles.InputRectangle
                id="suite_address"
                type="text"
                placeholder="Suite address"
                name="suite"
                {...register("suite")}
              />
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="row">
            <div className="col-12 col-sm-6 mb-2">
              <label htmlFor="city" className="mb-2">
                City
              </label>
              <TextStyles.InputRectangle
                id="city"
                type="text"
                placeholder="City"
                name="city"
                {...register("city")}
              />
            </div>
            <div className="col-12 col-sm-6 mb-2">
              <label htmlFor="zip_code" className="mb-2">
                Zip code
              </label>
              <TextStyles.InputRectangle
                id="zip_code"
                type="text"
                placeholder="Zip code"
                name="zip_code"
                {...register("zip_code")}
              />
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="row">
            <div className="col-12 col-sm-6 mb-2">
              <label htmlFor="state" className="mb-2">
                State
              </label>
              <TextStyles.InputRectangle
                id="state"
                type="text"
                placeholder="State"
                name="state"
                {...register("state")}
              />
            </div>
            <div className="col-12 col-sm-6 mb-2">
              <label htmlFor="country" className="mb-2">
                Country
              </label>
              <div>
                <Controller
                  control={control}
                  id="country"
                  name="country"
                  render={({ field: { onChange, value } }) => (
                    <SelectCountry
                      placeholder="Select country"
                      onChange={onChange}
                      defaultValue={value}
                    />
                  )}
                />
              </div>
            </div>
          </TextStyles.FontSize14px>
          <TextStyles.FontSize14px className="row">
            <div className="col-12 col-sm-6 mb-2">
              <label htmlFor="tax_id_label" className="mb-2">
                Tax ID label
              </label>
              <TextStyles.InputRectangle
                id="tax_id_label"
                type="text"
                placeholder="Tax ID label"
                name="tax_id_label"
                {...register("tax_id_label")}
              />
            </div>
            <div className="col-12 col-sm-6 mb-2">
              <label htmlFor="tax_id_number" className="mb-2">
                Tax ID number
              </label>
              <TextStyles.InputRectangle
                id="tax_id_number"
                type="text"
                placeholder="Tax ID number"
                name="tax_id_number"
                {...register("tax_id_number")}
              />
            </div>
          </TextStyles.FontSize14px>
          <Styled.TableHead>
            <TextStyles.GreyFont14px className="row">
              <div className="col-3">Name</div>
              <div className="col-7">Email</div>
              <div className="col-2 d-flex justify-content-center">Primary</div>
            </TextStyles.GreyFont14px>
          </Styled.TableHead>
          {selectedData &&
            selectedData.client_id &&
            selectedData.client_id.contacts &&
            selectedData.client_id.contacts.map((item, index) => {
              return (
                <Styled.TableCell key={index}>
                  <TextStyles.FontSize14px className="row">
                    <div className="col-3">
                      <div
                        className="d-flex align-items-center"
                        style={{ minWidth: 50 }}
                      >
                        <span className="three-dots">{item.contact_name}</span>
                      </div>
                    </div>
                    <div className="col-7">
                      <div
                        className="d-flex align-items-center"
                        style={{ minWidth: 100 }}
                      >
                        <span className="three-dots">{item.email}</span>
                      </div>
                    </div>
                    <div className="col-2 d-flex justify-content-center">
                      <TextStyles.RadioButton className="form-check">
                        <input
                          className="form-check-input"
                          style={{ margin: 0 }}
                          id="contact_primary"
                          name="contact_primary"
                          type="radio"
                          defaultChecked={item.email === selectedContact.email}
                          onChange={() => {
                            setSelectedContact(item);
                            // setPrimaryContact(false);
                          }}
                        />
                      </TextStyles.RadioButton>
                    </div>
                  </TextStyles.FontSize14px>
                </Styled.TableCell>
              );
            })}
          {addContact && (
            <Styled.TableCell>
              <TextStyles.FontSize14px className="row">
                <div className="col-3">
                  <input
                    style={{
                      border: "0px",
                    }}
                    id="contact_name"
                    type="text"
                    placeholder="Name"
                    name="contact_name"
                    {...register(`contact_name`, {
                      required: "Contact can't be empty.",
                      validate: (value) => {
                        let trimedLength = value.trim().length;
                        if (trimedLength === 0) {
                          return "Contact can't be empty.";
                        }
                        return true;
                      },
                    })}
                  />
                </div>
                <div className="col-7">
                  <input
                    style={{
                      border: "0px",
                    }}
                    id="contact_email"
                    type="text"
                    placeholder="Email"
                    name="contact_email"
                    {...register("contact_email", {
                      required: "Email is required.",
                      validate: validateEmail,
                      onBlur: (e) =>
                        setValue("contact_email", e.target.value.trim()),
                    })}
                  />
                </div>
                <div className="col-2 d-flex justify-content-center">
                  <TextStyles.RadioButton className="form-check">
                    <input
                      className="form-check-input"
                      style={{ margin: 0 }}
                      id="contact_primary"
                      name="contact_primary"
                      type="radio"
                      onChange={(e) => {
                        setPrimaryContact(
                          e.target.value === "on" ? true : false
                        );
                      }}
                    />
                  </TextStyles.RadioButton>
                </div>
              </TextStyles.FontSize14px>
            </Styled.TableCell>
          )}
          <TextStyles.FontSize14px className="d-flex justify-content-end my-2">
            <label
              htmlFor="contact_name"
              style={{ cursor: "pointer" }}
              onClick={() => setAddContact(true)}
            >
              {!addContact && (
                <>
                  <AddCircleOutlineTwoToneIcon
                    sx={{ color: Colors.font1, fontSize: 22 }}
                  />
                  <span className="ms-2">Add contact</span>
                </>
              )}
            </label>
          </TextStyles.FontSize14px>
        </Modal.Body>
        <Modal.Footer style={{ display: "inline-block" }}>
          <div className="d-flex gap-3 justify-content-around align-items-center">
            <TextStyles.Button
              disabled={
                errors.contact_name || errors.contact_email || isLoading
                  ? true
                  : false
              }
            >
              {isLoading ? <LoaderSpin /> : "Update client information"}
            </TextStyles.Button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PCEditClientInfo;
