import React from "react";
// for page one

import ProjectPageOne from "./PageOne/Project.jsx";
import ContractDateDetails from "./PageOne/ContractDateDetails.jsx";
import ClientAndContractor from "./PageOne/ClientAndContractor.jsx";
import RestOne from "./PageOne/RestOne.jsx";

// for page two

import ProjectPageTwo from "./PageTwo/Project.jsx";
import Services from "./PageTwo/Services.jsx";
import BillingSchedules from "./PageTwo/BillingSchedules.jsx";

// for page three

import PageThree from "./PageThree/PageThree.jsx";

// for page four

import PageFour from "./PageFour/PageFour.jsx";

// for page five
import PageFive from "./PageFive/PageFive.jsx";

// for page six
import PageSix from "./PageSix/PageSix.jsx";

import TextStyles from "../../../../../Constants/TextStyles";
import Styled from "./ReviewStyles";
import ListAddClause from "./AddClause/ListAddClause";
import Colors from "../../../../../Constants/Colors.js";

const Review = (props) => {
  const selectedData = props && props.selectedData;
  const data = props && props.data;
  const businessInfoData = props && props.businessInfoData;

  return (
    <div className="px-md-4 px-sm-3 px-2">
        <div
          className="p-3 p-md-4 mx-auto"
          style={{
            maxWidth: 800,
            backgroundColor: Colors.white,
          }}
        >
          <Styled.ReviewBox className="mb-4">
              <TextStyles.FontSize27px>
                Service contract
              </TextStyles.FontSize27px>
              <ProjectPageOne selectedData={selectedData} />
              <ContractDateDetails selectedData={selectedData} />
              <ClientAndContractor
                selectedData={selectedData}
                data={data}
                businessInfoData={businessInfoData}
              />
              <RestOne
                selectedData={selectedData}
                data={data}
                businessInfoData={businessInfoData}
              />
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
            <div>
              <TextStyles.FontSize27px
                className="d-flex w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots"> Attachment A : Services</span>
              </TextStyles.FontSize27px>
              <ProjectPageTwo selectedData={selectedData} />
              <Services selectedData={selectedData} />
              <BillingSchedules selectedData={selectedData} />
            </div>
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
              <TextStyles.FontSize27px
                className="d-flex w-100"
                style={{ minWidth: 100 }}
              >
                <span className="three-dots">
                  Attachment B : Terms and Conditions
                </span>
              </TextStyles.FontSize27px>
              <PageThree selectedData={selectedData} />
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
              <PageFour selectedData={selectedData} />
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
              <PageFive selectedData={selectedData} />
          </Styled.ReviewBox>
          <Styled.ReviewBox className="mb-4">
              <PageSix selectedData={selectedData} />
          </Styled.ReviewBox>

          {selectedData &&
            selectedData.add_clause &&
            selectedData.add_clause.filter(clause => clause.required === true).length > 0 && (
              <Styled.ReviewBox>
                <div>
                  {selectedData &&
                    selectedData.add_clause &&
                    selectedData.add_clause.map((data, index) => {
                      return <ListAddClause key={index} data={data} />;
                    })}
                </div>
              </Styled.ReviewBox>
            )}
        </div>
      </div>
  );
};

export default Review;
