import styled from "styled-components";
import Colors from "../../../../Constants/Colors";

const SaveAndCloseButtun = styled.div`
  font-size: 1.1rem;
  color: ${Colors.font2};
  font-weight: 500;
  opacity: 0.7;
  &:hover {
    opacity: 0.9;
  }
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 1.7rem;
  display: flex;
  font-family: Poppins;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  color: ${Colors.font2};
  background: rgb(218, 222, 236);
`;

export default {
  SaveAndCloseButtun,
  ProgressBar,
};
