import React from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";

import SuccessBtn from "../../../../Assets/assetsnew/success-symbol.svg";
import ClearIcon from "@mui/icons-material/Clear";

import TextStyles from "../../../../Constants/TextStyles";
import Colors from "../../../../Constants/Colors";
import { useDispatch } from "react-redux";
import { pcActions } from "../../../../store/storage/proposalAndContractSlice";

const PCDone = ({ show, handleClose, data, selectedData }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Modal
      show={show}
      onHide={handleClose}
      fullscreen
      dialogClassName="ToMakeFullscreenModal"
    >
      <div className="d-flex justify-content-end">
        <ClearIcon
          sx={{
            fontSize: "27px",
            margin: "1rem",
            color: Colors.font2,
            cursor: "pointer",
            opacity: 0.7,
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={() => {
            dispatch(pcActions.resetPcDataAction());
            history.push("/db/agreements");
          }}
        />
      </div>

      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={SuccessBtn} alt="successBtn" className="my-4" />
          <TextStyles.FontSize20px className="mt-2">
            {`${selectedData.section} sent`}
          </TextStyles.FontSize20px>
          <TextStyles.GreyFont14px className="mt-2">
            Check your inbox for a confirmation email
          </TextStyles.GreyFont14px>
          <TextStyles.GreyFont14px>{`(${data.email})`}</TextStyles.GreyFont14px>
          <TextStyles.Button
            className="mt-4 w-50"
            onClick={() => {
              dispatch(pcActions.resetPcDataAction());
              history.push("/db/home");
            }}
            style={{ color: Colors.white }}
          >
            Go to my desk
          </TextStyles.Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PCDone;
