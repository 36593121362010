import React, { useState, useEffect } from "react";

import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { AutoCompleteSelectClient } from "../../../Commons/AutoComplete";

import BillingSchedule from "./BillingSchedule";
import Services from "./Services";

import Styled from "./SetupProjectStyles";
import TextStyles from "../../../../../Constants/TextStyles";
import SwitchButton from "../../../Commons/SwitchButton";
import Colors from "../../../../../Constants/Colors";
import SelectCurrency from "../../../Commons/SelectCurrency";
import moment from "moment";
import { invoiceActions } from "../../../../../store/storage/invoiceSlice";
import { useHistory } from "react-router-dom";
import CustomDatePickerOne from "../../../Commons/DatePickers/CustomDatePickerOne";

const SetupProject = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedInvoiceData = useSelector(
    (state) => state.invoices.selectedInvoiceData
  );

  const clientOptionData = useSelector(
    (state) => state.clients.clientOptionData
  );

  const project_id = selectedInvoiceData && selectedInvoiceData.project_id;
  const userCurrency = useSelector((state) => state.auth.userCurrency);

  const [selectEndDate, setSelectEndDate] = useState(
    project_id && project_id.project_end_date ? false : true
  );

  let billing_scheduled = project_id && project_id.billing_scheduled;

  const cleintDetails =
    selectedInvoiceData &&
    selectedInvoiceData.client_id &&
    selectedInvoiceData.client_id;

  const selectedExistingClientData =
    clientOptionData && cleintDetails && cleintDetails._id !== null
      ? clientOptionData
          .filter(
            (client) => client._id === (cleintDetails && cleintDetails._id)
          )
          .map((data) => {
            return { label: data.company_name, _id: data._id };
          })[0]
      : { label: cleintDetails && cleintDetails.company_name, _id: null };

  const {
    register,
    reset,
    watch,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      servicesList:
        project_id && project_id.service_list
          ? project_id.service_list
          : [
              {
                service_name: "",
                service_rate: "",
                service_rate_type: "Flat fee",
                desc: "",
              },
            ],
      project_name: project_id && project_id.project_name,
      client: selectedExistingClientData,
      currency:
        project_id && project_id.currency
          ? project_id.currency
          : userCurrency && userCurrency.currency,

      project_start_date:
        project_id && project_id.project_start_date
          ? moment(project_id.project_start_date).toDate()
          : moment().toDate(),
      project_end_date:
        project_id &&
        project_id.project_end_date &&
        moment(project_id.project_end_date).toDate(),
    },
    billingScheduled: {
      deposit: {
        require_deposit:
          billing_scheduled &&
          billing_scheduled.deposit &&
          billing_scheduled.deposit.require_deposit,
        value:
          billing_scheduled &&
          billing_scheduled.deposit &&
          billing_scheduled.deposit.value,
      },
      issue_invoice:
        billing_scheduled &&
        billing_scheduled.issue_invoice &&
        moment(billing_scheduled.issue_invoice).toDate(),
      weekly_invoice: {
        start_date:
          billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.start_date &&
          moment(billing_scheduled.weekly_invoice.start_date).toDate(),
        invoices_type:
          billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.invoices_type,
        num_invoices:
          billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.num_invoices,
        end_date:
          billing_scheduled &&
          billing_scheduled.weekly_invoice &&
          billing_scheduled.weekly_invoice.end_date &&
          moment(billing_scheduled.weekly_invoice.end_date).toDate(),
      },
      biWeekly_invoice: {
        start_date:
          billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.start_date &&
          moment(billing_scheduled.biWeekly_invoice.start_date).toDate(),
        invoices_type:
          billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.invoices_type,
        num_invoices:
          billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.num_invoices,
        end_date:
          billing_scheduled &&
          billing_scheduled.biWeekly_invoice &&
          billing_scheduled.biWeekly_invoice.end_date &&
          moment(billing_scheduled.biWeekly_invoice.end_date).toDate(),
      },
      monthly_invoice: {
        start_date:
          billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.start_date &&
          moment(billing_scheduled.monthly_invoice.start_date).toDate(),
        invoices_type:
          billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.invoices_type,
        num_invoices:
          billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.num_invoices,
        end_date:
          billing_scheduled &&
          billing_scheduled.monthly_invoice &&
          billing_scheduled.monthly_invoice.end_date &&
          moment(billing_scheduled.monthly_invoice.end_date).toDate(),
      },
    },
  });

  const changedCurrency = watch("currency");
  const currencySymbol = changedCurrency
    ? changedCurrency.symbol
    : userCurrency && userCurrency.currency && userCurrency.currency.symbol;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "servicesList",
  });

  const [endDateErrorMessage, setEndDateErrorMessage] = useState(null);
  const startDate = watch("project_start_date");
  const endDate = watch("project_end_date");

  const validateEndDate = () => {
    const eDate = endDate && moment(endDate);
    const sDate = moment(startDate);
    if (eDate && eDate.isBefore(sDate, "day")) {
      setEndDateErrorMessage(
        `End date should be greater than ${moment(startDate)
          .subtract(1, "d")
          .format("DD MMM YYYY")}.`
      );
      return false;
    } else {
      setEndDateErrorMessage(null);
      return true;
    }
  };

  useEffect(() => {
    validateEndDate();
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectEndDate) {
      setValue("project_end_date", null);
    } else {
      setValue(
        "project_end_date",
        project_id &&
          project_id.project_end_date &&
          moment(project_id.project_end_date).toDate()
      );
    }
  }, [selectEndDate]);

  const formsubmit = (data) => {
    const billing_scheduled = {
      deposit: data.billingScheduled && data.billingScheduled.deposit,
      invoice_frequency:
        data.billingScheduled && data.billingScheduled.invoice_frequency,
      issue_invoice:
        data.billingScheduled && data.billingScheduled.issue_invoice
          ? moment(data.billingScheduled.issue_invoice).toISOString()
          : undefined,
      weekly_invoice: data.billingScheduled &&
        data.billingScheduled.weekly_invoice && {
          start_date: data.billingScheduled.weekly_invoice.start_date
            ? moment(
                data.billingScheduled.weekly_invoice.start_date
              ).toISOString()
            : undefined,
          end_date: data.billingScheduled.weekly_invoice.end_date
            ? moment(
                data.billingScheduled.weekly_invoice.end_date
              ).toISOString()
            : undefined,
          num_invoices: data.billingScheduled.weekly_invoice.num_invoices
            ? data.billingScheduled.weekly_invoice.num_invoices
            : undefined,
          invoices_type: data.billingScheduled.weekly_invoice.invoices_type
            ? data.billingScheduled.weekly_invoice.invoices_type
            : "Never",
        },
      biWeekly_invoice: data.billingScheduled &&
        data.billingScheduled.biWeekly_invoice && {
          start_date: data.billingScheduled.biWeekly_invoice.start_date
            ? moment(
                data.billingScheduled.biWeekly_invoice.start_date
              ).toISOString()
            : undefined,
          end_date: data.billingScheduled.biWeekly_invoice.end_date
            ? moment(
                data.billingScheduled.biWeekly_invoice.end_date
              ).toISOString()
            : undefined,
          num_invoices: data.billingScheduled.biWeekly_invoice.num_invoices
            ? data.billingScheduled.biWeekly_invoice.num_invoices
            : undefined,
          invoices_type: data.billingScheduled.biWeekly_invoice.invoices_type
            ? data.billingScheduled.biWeekly_invoice.invoices_type
            : "Never",
        },
      monthly_invoice: data.billingScheduled &&
        data.billingScheduled.monthly_invoice && {
          start_date: data.billingScheduled.monthly_invoice.start_date
            ? moment(
                data.billingScheduled.monthly_invoice.start_date
              ).toISOString()
            : undefined,
          end_date: data.billingScheduled.monthly_invoice.end_date
            ? moment(
                data.billingScheduled.monthly_invoice.end_date
              ).toISOString()
            : undefined,
          num_invoices: data.billingScheduled.monthly_invoice.num_invoices
            ? data.billingScheduled.monthly_invoice.num_invoices
            : undefined,
          invoices_type: data.billingScheduled.monthly_invoice.invoices_type
            ? data.billingScheduled.monthly_invoice.invoices_type
            : "Never",
        },
    };
    const project_id = {
      _id: null,
      project_name: data.project_name,
      client_id: data.client && data.client._id,
      project_start_date:
        data.project_start_date &&
        moment(data.project_start_date).toISOString(),
      project_end_date:
        selectEndDate || data.project_end_date === undefined
          ? null
          : data.project_end_date &&
            moment(data.project_end_date).toISOString(),
      currency: data.currency ? data.currency : userCurrency.currency,
      service_list: data.servicesList,
      billing_scheduled: billing_scheduled,
    };
    let client_id;
    if (project_id && project_id.client_id) {
      client_id =
        clientOptionData &&
        clientOptionData.filter(
          (client) => client._id === project_id.client_id
        )[0];
    } else {
      client_id = cleintDetails;
    }
    dispatch(
      invoiceActions.selectedInvoiceAction({
        project_id,
        client_id,
        isProjectModified: dirtyFields.servicesList ? true : false,
      })
    );
    history.push("/db/invoices/create/setup");
  };

  return (
    <div className="d-flex justify-content-center px-2 py-4">
      <div style={{ maxWidth: 600, width: "100%" }}>
        <form onSubmit={handleSubmit(formsubmit)}>
          <TextStyles.FontSize27px className="text-center font-weight-500 mb-4">
            Create project
          </TextStyles.FontSize27px>
          <Styled.SetupBox className="mb-4">
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="project_name" className="mb-2">
                Project name
              </label>
              <TextStyles.InputRectangle
                name="project_name"
                invalid={errors.project_name}
                type="text"
                placeholder="Project name"
                {...register("project_name", {
                  required: "Project name is required.",
                  validate: (value) => {
                    let trimedLength = value.trim().length;
                    if (trimedLength === 0) {
                      return "Project name is required.";
                    }
                    if (trimedLength < 3) {
                      return "Project name must be at least 3 characters";
                    }
                    if (trimedLength > 30) {
                      return "Project name must be at most 30 characters";
                    }
                    return true;
                  },
                })}
              />
              {errors.project_name && (
                <TextStyles.InValidFeedback>
                  {errors.project_name.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="client" className="mb-2">
                Select client
              </label>
              <Controller
                control={control}
                name="client"
                rules={{
                  required: {
                    value: true,
                    message: "Client is required.",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <AutoCompleteSelectClient
                    placeholder="Choose a client"
                    options={
                      clientOptionData &&
                      clientOptionData.map((data) => {
                        return { label: data.company_name, _id: data._id };
                      })
                    }
                    onChange={onChange}
                    defaultValue={value}
                    invalid={errors.client}
                  />
                )}
              />
              {errors.client && (
                <TextStyles.InValidFeedback className="mt-2">
                  {errors.client.message}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <label htmlFor="client" className="mb-2">
                Select currency
              </label>

              <Controller
                control={control}
                name="currency"
                render={({ field: { onChange, value } }) => (
                  <SelectCurrency
                    placeholder="Select currency"
                    onChange={onChange}
                    defaultValue={value}
                  />
                )}
              />
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div htmlFor="project_start_date" className="mb-2">
                Start date
              </div>
              <div>
                <Controller
                  name={"project_start_date"}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CustomDatePickerOne
                        onChange={onChange}
                        selected={value}
                        placeholder="Select start date"
                        allowClear={false}
                      />
                    );
                  }}
                />
              </div>
            </TextStyles.FontSize14px>
            <TextStyles.FontSize14px className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div htmlFor="project_end_date">End date</div>
                <div className="d-flex align-items-center">
                  <TextStyles.FontSize14px
                    style={{ color: Colors.primary, fontWeight: 500 }}
                  >
                    Ongoing
                  </TextStyles.FontSize14px>
                  <SwitchButton
                    defaultChecked={selectEndDate}
                    onChange={(event) => setSelectEndDate(event.target.checked)}
                  />
                </div>
              </div>
              {!selectEndDate && (
                <Controller
                  name={"project_end_date"}
                  control={control}
                  rules={{ validate: validateEndDate }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <CustomDatePickerOne
                        onChange={onChange}
                        selected={value}
                        placeholder="Select end date"
                        allowClear={true}
                      />
                    );
                  }}
                />
              )}
              {endDateErrorMessage && (
                <TextStyles.InValidFeedback className="mt-2">
                  {endDateErrorMessage}
                </TextStyles.InValidFeedback>
              )}
            </TextStyles.FontSize14px>
          </Styled.SetupBox>

          <Services
            currencySymbol={currencySymbol}
            control={control}
            Controller={Controller}
            watch={watch}
            register={register}
            fields={fields}
            remove={remove}
            append={append}
          />
          <BillingSchedule
            currencySymbol={currencySymbol}
            billing_scheduled={billing_scheduled}
            register={register}
            watch={watch}
            setValue={setValue}
            Controller={Controller}
            control={control}
            reset={reset}
          />
          <div className="my-3">
            <Styled.NextButton>Next</Styled.NextButton>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetupProject;
